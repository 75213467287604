import { Box, Typography, Container } from "@mui/material";
import { useEffect } from "react";
import Config from '../models/Config'; 

function AboutPage() {
  useEffect(() => {
    document.title = `${Config.TAB_PREFIX}About EZ Run PBRA`
  }, [])
  return (
    <>
      <Container maxWidth="md" className="text-align-center">
        <Typography component="h4" variant="h4" marginBottom={4}>
          About EZ Run PBRA
        </Typography>
        <Typography component="h5" variant="h5">
          Order of Events at Shows
        </Typography>
        <Typography component="p" variant="body1">
          Pee Wee Barrels (10 & under)
          <br />
          Youth Barrels (15 & under)
          <br />
          Open 4D Barrels
          <br />
          Exhibition Barrels
        </Typography>
        <Typography component="p" variant="subtitle2" fontStyle={"italic"} marginBottom={2}>
          Divisions are on a .5 second split
          <br />
          Shows are 80% payback
          <br />
          All fees must be paid in cash
        </Typography>
        <Typography component="h5" variant="h5">
          Dress Code
        </Typography>
        <Typography component="p" variant="subtitle2" fontStyle={"italic"} marginBottom={2}>
          (Unless otherwise posted)
        </Typography>
        <Typography component="p" variant="body1" marginBottom={3}>
          Shirt of your choice tucked in (No cami tops)
          <br />
          Jeans no shorts or capris
          <br />
          Boots no tennis shoes
          <br />
          Riders 12 & under mandatory helmet
        </Typography>
        <Typography component="h5" variant="h5">
          Association Contact Information
        </Typography>
        <Typography component="p" variant="body1">
          919 795 5354
          <br />
          EZRunPro@gmail.com
          <br />
        </Typography>
      </Container>
    </>
  );
}

export default AboutPage;
