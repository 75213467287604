import { useEffect, useMemo, useState } from "react";
import Config from "../models/Config";
import useAuth from "../hooks/useAuth";
import Container from '@mui/material/Container';
import PageHeader from "../components/PageHeader";
import Stack from '@mui/material/Stack';
import Sponsor from "../models/Sponsor";
import LoadingOverlay from "react-loading-overlay-ts";
import Cache from "../models/Cache";
import SponsorLevel from "../models/SponsorLevel";

export default function SponsorsPage() {
  const user = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<SponsorLevel[]>([]);

  const getSponsors = useMemo(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetch(`${Config.SPONSORS_SHEET}/values/${encodeURIComponent("Sponsors!A2:D")}?key=${Config.SHEETS_KEY}`, {
          method: 'GET'
        });
        const jsonData = await data.json();
        const sponsors = jsonData.values.map((value: any) => new Sponsor(value));
        const sponsorsByLevel: SponsorLevel[] = [];
        sponsors.forEach((sponsor: Sponsor) => {
          const sponsorLevel = sponsorsByLevel.find((level) => level.level === sponsor.level);
          if (sponsorLevel) {
            sponsorLevel.sponsors.push(sponsor);
          } else {
            const newSponsorLevel = new SponsorLevel(sponsor.level);
            newSponsorLevel.sponsors.push(sponsor);
            sponsorsByLevel.push(newSponsorLevel);
          }
        });
        Cache.SponsorsPage.data = sponsorsByLevel;
        Cache.SponsorsPage.timestamp = Date.now();
        setResult(sponsorsByLevel);
      } catch (error) {
        // Handle error
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    return async () => {
      if (Date.now() - Cache.SponsorsPage.timestamp < Config.CACHE_EXPIRATION_TIME) {
        setResult(Cache.SponsorsPage.data);
      } else {
        fetchData();
      }
    };
  }, [user]);

  useEffect(() => {
    getSponsors();
  }, [getSponsors]);
  
  useEffect(() => {
    document.title = `${Config.TAB_PREFIX}Sponsors`
  }, [])
  
  const openTab = (url: string) => {
   if (url) {
    window.open(url, "_blank", "noopener,noreferrer");
   }
  };
  return (
    <LoadingOverlay active={isLoading} className="loading-overlay">
      <Container maxWidth="sm" sx={{ marginBottom: '60px' }}>
        <PageHeader header="Sponsors" />
        <Stack spacing={2} marginTop={2}>
        {result &&
              result.map((sponsorLevel: SponsorLevel, index) => (
                <div key={index}>
                  <div className="center-text">
                    <h2>{sponsorLevel.level}{sponsorLevel.hasMultipleSponsors ? 's' : ''}</h2>
                  </div>
                  <div className={`grid-container ${sponsorLevel.sponsors?.length === 1 ? "single-column" : ""}`}>
                    {sponsorLevel.sponsors && sponsorLevel.sponsors.map((sponsor: Sponsor, sponsorIndex: number) => (
                      <div className="grid-item card-row" key={sponsorIndex}>
                       {sponsor.image && (
                         <img
                         className="pointer center img"
                         src={sponsor.image}
                         alt={sponsor.name}
                         onClick={() => openTab(sponsor.url)}
                       />)}
                       {!sponsor.image && (
                          <h1
                          className="pointer center img"
                          onClick={() => openTab(sponsor.url)}
                        >
                          {sponsor.name}
                          </h1>
                       )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
        </Stack>
      </Container>
    </LoadingOverlay>
  );
  }