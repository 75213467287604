import Sponsor from "./Sponsor";

export default class SponsorLevel{
    level: string;
    sponsors: Sponsor[];
    get hasMultipleSponsors(): boolean {
        return this.sponsors?.length > 1;
    }
    constructor(level: string) {
        this.level = level;
        this.sponsors = [];
    }
}